class ZIDXAccountDataFeedEdit implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountDataFeedEditContainer";
    }
    getMatchURL(){
        return "/account/data-feeds/edit";
    }
    render(){
        // console.log("data feed edit");
        ZIDX.$("#listing_feed_enable_listing_download1").on("click", function(e:ClickEvent){
            let listing_feed_listing_timestamp_field=ZIDX.$("#listing_feed_listing_timestamp_field", ZIDX.$(".zidxPageActive")).val();
            let listing_feed_photo_timestamp_field=ZIDX.$("#listing_feed_photo_timestamp_field", ZIDX.$(".zidxPageActive")).val();
            let listing_feed_listing_id_field=ZIDX.$("#listing_feed_listing_id_field", ZIDX.$(".zidxPageActive")).val();
            let listing_feed_listing_type_field=ZIDX.$("#listing_feed_listing_type_field", ZIDX.$(".zidxPageActive")).val();
            let listing_feed_photo_count_field=ZIDX.$("#listing_feed_photo_count_field", ZIDX.$(".zidxPageActive")).val();
            let listing_feed_media_field=ZIDX.$("#listing_feed_media_field", ZIDX.$(".zidxPageActive")).val();
            let listing_feed_photo_key_field=ZIDX.$("#listing_feed_photo_key_field", ZIDX.$(".zidxPageActive")).val();
            let propertySettingCount=0;
            if(listing_feed_listing_timestamp_field==""){
                propertySettingCount++;
            }
            if(listing_feed_photo_timestamp_field==""){
                propertySettingCount++;
            }
            if(listing_feed_listing_id_field==""){
                propertySettingCount++;
            }
            if(listing_feed_listing_type_field==""){
                propertySettingCount++;
            }
            if(listing_feed_photo_count_field==""){
                propertySettingCount++;
            }
            // if(listing_feed_media_field==""){
            //     propertySettingCount++;
            // }
            // if(listing_feed_photo_key_field==""){
            //     propertySettingCount++;
            // }
            if(propertySettingCount!=0) {
                alert("You can't enable listing data download unless all \"Property Settings\" are populated first.");
                ZIDX.$("#listing_feed_enable_listing_download0").trigger("click");
            }
        });
    }
}